import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  so1: any;
  so2: any;
  an: any;
  gr: any;
  cov; sp;
  mdata;
  ylo;
  diw;
  bgc: any;
  constructor() {

    this.bgc = ["bg-danger  text-white", "bg-warning text-dark", "bg-light text-dark shadow", "bg-info text-white", "bg-success text-white"];
  }

  ngOnInit() {
    this.so1 = []
    // for(let i=1;i<42;i++){
    this.so1.push(
      { imaddr: "assets/img/special/notebook.jpg", iminfo: "Free NoteBook provided by our Contributor", multi: false },
      { imaddr: "assets/img/special/donr.jpg", iminfo: "Madurai Charitable Trust Visit", multi: false },
      { imaddr: "assets/img/special/Dengue Pledge.jpg", iminfo: "Awarness of Dengue Diseases", multi: false },
      { imaddr: "assets/img/special/Deworming Day.jpg", iminfo: "Awarness of Deworming Day", multi: false },
      { imaddr: "assets/img/special/education Development.jpg", iminfo: "Celebrated Education Development Day on the Kamarajar Birthday", multi: false },
      { imaddr: "assets/img/special/Greenday.jpg", iminfo: "Donors visited on Green Color Day", multi: false },
      { imaddr: "assets/img/special/Save water.jpg", iminfo: "Rally for Save Water", multi: false },
      { imaddr: "assets/img/special/ittalent2.jpg", iminfo: "IT Talent Day", multi: false },
      { imaddr: "assets/img/special/red day.jpg", iminfo: "Red Color Day", multi: false },
      { imaddr: "assets/img/special/pta.jpg", iminfo: "Parent Orientation Programme", multi: false },
      { imaddr: "assets/img/special/rwh.jpg", iminfo: "Awareness of Rain Water Harvesting to Surrounded People", multi: false },
      { imaddr: "assets/img/special/Swachhta.jpg", iminfo: "Awareness of Cleaned Environment on Swachhta Seva Day", multi: false },
      // {
      //   imaddr: ["assets/img/special/ptav/1.JPG",
      //     "assets/img/special/ptav/2.JPG",
      //     "assets/img/special/ptav/3.JPG",
      //     "assets/img/special/ptav/4.JPG",
      //     "assets/img/special/ptav/5.JPG",
      //     "assets/img/special/ptav/6.JPG",], iminfo: "Parent Teacher Association Meet NKKR Vidhyalaya", multi: true
      // },
      // {
      //   imaddr: ["assets/img/special/ptanp/1.JPG",
      //     "assets/img/special/ptanp/2.JPG",
      //     "assets/img/special/ptanp/3.JPG",
      //     "assets/img/special/ptanp/4.JPG",
      //     "assets/img/special/ptanp/5.JPG",
      //     "assets/img/special/ptanp/6.JPG",], iminfo: "Parent Teacher Association Meet NKKR Nursery primary", multi: true
      // },
    )
    this.mdata = [
      {
        evntid: "rep",
        evntname: "Republic Day",
        evntquote: "Celebrated 76th Republic Day",
        evntimage: [
          { imaddr: "assets/img/rep/76/1", iminfo: "" },
          { imaddr: "assets/img/rep/76/35", iminfo: "<h5>S.R. Sundarnath</h5><br><h6>Founder <br>AMARTEK LOCKERS</h6>" },
          { imaddr: "assets/img/rep/76/8", iminfo: "" },
          { imaddr: "assets/img/rep/76/3", iminfo: "" },
          { imaddr: "assets/img/rep/76/4", iminfo: "" },
          { imaddr: "assets/img/rep/76/5", iminfo: "" },
          { imaddr: "assets/img/rep/76/6", iminfo: "" },
          { imaddr: "assets/img/rep/76/7", iminfo: "" },
          { imaddr: "assets/img/rep/76/8", iminfo: "" },
          { imaddr: "assets/img/rep/76/9", iminfo: "" },
          { imaddr: "assets/img/rep/76/10", iminfo: "" },
          { imaddr: "assets/img/rep/76/11", iminfo: "" },
          { imaddr: "assets/img/rep/76/12", iminfo: "" },
          { imaddr: "assets/img/rep/76/13", iminfo: "" },
          { imaddr: "assets/img/rep/76/14", iminfo: "" },
          { imaddr: "assets/img/rep/76/15", iminfo: "" },
          { imaddr: "assets/img/rep/76/16", iminfo: "" },
          { imaddr: "assets/img/rep/76/17", iminfo: "" },
          { imaddr: "assets/img/rep/76/18", iminfo: "" },
          { imaddr: "assets/img/rep/76/19", iminfo: "" },
          { imaddr: "assets/img/rep/76/20", iminfo: "" },
          { imaddr: "assets/img/rep/76/21", iminfo: "" },
          { imaddr: "assets/img/rep/76/22", iminfo: "" },
          { imaddr: "assets/img/rep/76/24", iminfo: "" },
          { imaddr: "assets/img/rep/76/25", iminfo: "" },
          { imaddr: "assets/img/rep/76/34", iminfo: "<h5>G R Subramanian</h5><br><h6>Sri Sankari Silks</h6>" },
          { imaddr: "assets/img/rep/76/27", iminfo: "" },
          { imaddr: "assets/img/rep/76/28", iminfo: "" },
          { imaddr: "assets/img/rep/76/29", iminfo: "" },
          { imaddr: "assets/img/rep/76/30", iminfo: "" },
          { imaddr: "assets/img/rep/76/31", iminfo: "" },
          { imaddr: "assets/img/rep/76/32", iminfo: "" },
          { imaddr: "assets/img/rep/76/33", iminfo: "" },
          { imaddr: "assets/img/rep/76/26", iminfo: "" },
          { imaddr: "assets/img/rep/76/2", iminfo: "" },
          { imaddr: "assets/img/rep/76/36", iminfo: "" },
          { imaddr: "assets/img/rep/76/37", iminfo: "" },
          

        ]
      },
      // T gurusamy founder star friends trust
      {
        evntid: "so2",
        evntname: "Science Exibhition",
        evntquote: "Science Occupy Everywhere",
        evntimage: [
          { imaddr: "assets/img/sci/2024/1", iminfo: "" },
          { imaddr: "assets/img/sci/2024/2", iminfo: "<h5>Y.R. Janarthanan</h5><br><h6>Scientist-G (Rtd)<br><br>DRDO, INDIA</h6>" },
          { imaddr: "assets/img/sci/2024/3", iminfo: "<h5>M.R. Govarthanan</h5><br><h6>Vijay Tex,<br><br>Handloom Sarees Manufacturer</h6>" },
          { imaddr: "assets/img/sci/2024/4", iminfo: "" },
          { imaddr: "assets/img/sci/2024/5", iminfo: "" },
          { imaddr: "assets/img/sci/2024/6", iminfo: "" },
          { imaddr: "assets/img/sci/2024/7", iminfo: "" },
          { imaddr: "assets/img/sci/2024/8", iminfo: "" },
          { imaddr: "assets/img/sci/2024/9", iminfo: "" },
          { imaddr: "assets/img/sci/2024/10", iminfo: "" },
          { imaddr: "assets/img/sci/2024/11", iminfo: "" },
          { imaddr: "assets/img/sci/2024/12", iminfo: "" },
          { imaddr: "assets/img/sci/2024/13", iminfo: "" },
          { imaddr: "assets/img/sci/2024/14", iminfo: "" },
          { imaddr: "assets/img/sci/2024/15", iminfo: "" },
          { imaddr: "assets/img/sci/2024/16", iminfo: "" },
          { imaddr: "assets/img/sci/2024/17", iminfo: "" },
          { imaddr: "assets/img/sci/2024/18", iminfo: "" },
          { imaddr: "assets/img/sci/2024/19", iminfo: "" },
          { imaddr: "assets/img/sci/2024/20", iminfo: "" },
          { imaddr: "assets/img/sci/2024/21", iminfo: "" },
          { imaddr: "assets/img/sci/2024/22", iminfo: "" },
          { imaddr: "assets/img/sci/2024/24", iminfo: "" },
          { imaddr: "assets/img/sci/2024/25", iminfo: "" },
          { imaddr: "assets/img/sci/2024/26", iminfo: "" },
          { imaddr: "assets/img/sci/2024/27", iminfo: "" },
          { imaddr: "assets/img/sci/2024/28", iminfo: "" },
          { imaddr: "assets/img/sci/2024/29", iminfo: "" },
          { imaddr: "assets/img/sci/2024/30", iminfo: "" },
          { imaddr: "assets/img/sci/2024/30", iminfo: "" },
          { imaddr: "assets/img/sci/2024/31", iminfo: "" },
          { imaddr: "assets/img/sci/2024/32", iminfo: "" },
          { imaddr: "assets/img/sci/2024/34", iminfo: "" },
          { imaddr: "assets/img/sci/2024/35", iminfo: "" },
          { imaddr: "assets/img/sci/2024/36", iminfo: "" },
          { imaddr: "assets/img/sci/2024/37", iminfo: "" },
       
        ],
      },
      {
        evntid: "diwali",
        evntname: "Deepavali Celebration",
        evntquote: "Celebrated Deepavali with Souco Group",
        evntimage: [
           { imaddr:"assets/img/diwali/24/1",iminfo: "" },
           { imaddr:"assets/img/diwali/24/2",iminfo: "" },
           { imaddr:"assets/img/diwali/24/3",iminfo: "" },
           { imaddr:"assets/img/diwali/24/4",iminfo: "" },
           { imaddr:"assets/img/diwali/24/5",iminfo: "" },
           { imaddr:"assets/img/diwali/24/6",iminfo: "" },
           { imaddr:"assets/img/diwali/24/7",iminfo: "" },
           { imaddr:"assets/img/diwali/24/8",iminfo: "" },
           { imaddr:"assets/img/diwali/24/9",iminfo: "" },
           { imaddr:"assets/img/diwali/24/10",iminfo: "" },
           { imaddr:"assets/img/diwali/24/11",iminfo: "" },
           { imaddr:"assets/img/diwali/24/12",iminfo: "" },
           { imaddr:"assets/img/diwali/24/14",iminfo: "" },
           { imaddr:"assets/img/diwali/24/15",iminfo: "" },
           { imaddr:"assets/img/diwali/24/16",iminfo: "" },
           { imaddr:"assets/img/diwali/24/17",iminfo: "" },
           { imaddr:"assets/img/diwali/24/18",iminfo: "" },
           { imaddr:"assets/img/diwali/24/19",iminfo: "" },
           { imaddr:"assets/img/diwali/24/20",iminfo: "" },
           { imaddr:"assets/img/diwali/24/21",iminfo: "" },
           { imaddr:"assets/img/diwali/24/22",iminfo: "" },
           { imaddr:"assets/img/diwali/24/23",iminfo: "" },
           { imaddr:"assets/img/diwali/24/24",iminfo: "" },
           { imaddr:"assets/img/diwali/24/25",iminfo: "" },
           { imaddr:"assets/img/diwali/24/26",iminfo: "" },

        ],
      },
      {
        evntid: "ind",
        evntname: "Independence Day",
        evntquote: "Celebrated 78th Independence Day",
        evntimage: [
          { imaddr: "assets/img/ind/2024/1", iminfo: "" },
          { imaddr: "assets/img/ind/2024/2", iminfo: "" },
          { imaddr: "assets/img/ind/2024/3", iminfo: "" },
          { imaddr: "assets/img/ind/2024/4", iminfo: "" },
          { imaddr: "assets/img/ind/2024/5", iminfo: "" },
          { imaddr: "assets/img/ind/2024/6", iminfo: "" },
          { imaddr: "assets/img/ind/2024/7", iminfo: "" },
          { imaddr: "assets/img/ind/2024/8", iminfo: "" },
          { imaddr: "assets/img/ind/2024/9", iminfo: "" },
          { imaddr: "assets/img/ind/2024/10", iminfo: "" },
          { imaddr: "assets/img/ind/2024/11", iminfo: "" },
          { imaddr: "assets/img/ind/2024/12", iminfo: "" },
          { imaddr: "assets/img/ind/2024/13", iminfo: "" },
          { imaddr: "assets/img/ind/2024/14", iminfo: "" },
          { imaddr: "assets/img/ind/2024/15", iminfo: "" },
          { imaddr: "assets/img/ind/2024/16", iminfo: "" },
          { imaddr: "assets/img/ind/2024/17", iminfo: "" },
          { imaddr: "assets/img/ind/2024/18", iminfo: "" },
          { imaddr: "assets/img/ind/2024/19", iminfo: "" },
          { imaddr: "assets/img/ind/2024/20", iminfo: "<h5>R.J. Anangan Babu</h5><br><h6>Secretary,<br>Tamil Arts and Culture Association Inc<br>Sydney, Australia</h6>" },
          { imaddr: "assets/img/ind/2024/21", iminfo: "" },
          { imaddr: "assets/img/ind/2024/22", iminfo: "" },
          { imaddr: "assets/img/ind/2024/23", iminfo: "" },
          { imaddr: "assets/img/ind/2024/24", iminfo: "" },
          { imaddr: "assets/img/ind/2024/25", iminfo: "" },
          { imaddr: "assets/img/ind/2024/26", iminfo: "" },
          { imaddr: "assets/img/ind/2024/27", iminfo: "" },
          { imaddr: "assets/img/ind/2024/28", iminfo: "" },
          { imaddr: "assets/img/ind/2024/29", iminfo: "" },
          { imaddr: "assets/img/ind/2024/30", iminfo: "<h5>B.S. Anandhan</h5><br><h6>Managing Director<br>ARP Offset and Sri Meenakshi Printers,<br>Chennai</h6>" },
          { imaddr: "assets/img/ind/2024/31", iminfo: "" },
          { imaddr: "assets/img/ind/2024/32", iminfo: "" },
          { imaddr: "assets/img/ind/2024/33", iminfo: "" },
          { imaddr: "assets/img/ind/2024/34", iminfo: "" },
          { imaddr: "assets/img/ind/2024/35", iminfo: "" },
          { imaddr: "assets/img/ind/2024/36", iminfo: "" },
          { imaddr: "assets/img/ind/2024/37", iminfo: "" },
          { imaddr: "assets/img/ind/2024/38", iminfo: "" },
          { imaddr: "assets/img/ind/2024/39", iminfo: "" },
          { imaddr: "assets/img/ind/2024/40", iminfo: "" },
          { imaddr: "assets/img/ind/2024/41", iminfo: "" },
          { imaddr: "assets/img/ind/2024/42", iminfo: "" },
          { imaddr: "assets/img/ind/2024/43", iminfo: "" },
          { imaddr: "assets/img/ind/2024/44", iminfo: "" },
          { imaddr: "assets/img/ind/2024/45", iminfo: "" },
          { imaddr: "assets/img/ind/2024/46", iminfo: "" },
        ],
      },




      {
        evntid: "ind",
        evntname: "Sports Day",
        evntquote: "Sports Day 2024",
        evntimage: [
          { imaddr: "assets/img/sports/sports2024/1", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/2", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/3", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/4", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/5", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/6", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/7", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/8", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/9", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/10", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/11", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/12", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/13", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/14", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/15", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/16", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/17", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/18", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/19", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/20", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/21", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/22", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/23", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/24", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/25", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/26", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/27", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/28", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/29", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/30", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/31", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/32", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/33", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/34", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/35", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/36", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/37", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/38", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/39", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/40", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/41", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/42", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/43", iminfo: "" },
          { imaddr: "assets/img/sports/sports2024/44", iminfo: "" },
        ],
      },
      {
        evntid: "an",
        evntname: "Founder's Day",
        evntquote: "Our Founder's 100th Birthday",
        evntimage: [
          { imaddr: "assets/img/special/k100th/1", iminfo: "" },
          { imaddr: "assets/img/special/k100th/2", iminfo: "" },
          { imaddr: "assets/img/special/k100th/3", iminfo: "" },
          { imaddr: "assets/img/special/k100th/4", iminfo: "" },
          { imaddr: "assets/img/special/k100th/5", iminfo: "" },
          { imaddr: "assets/img/special/k100th/6", iminfo: "" },
          { imaddr: "assets/img/special/k100th/7", iminfo: "" },
          { imaddr: "assets/img/special/k100th/8", iminfo: "" },
          { imaddr: "assets/img/special/k100th/9", iminfo: "" },
          { imaddr: "assets/img/special/k100th/10", iminfo: "" },
          { imaddr: "assets/img/special/k100th/11", iminfo: "" },
          { imaddr: "assets/img/special/k100th/12", iminfo: "" },
          { imaddr: "assets/img/special/k100th/14", iminfo: "" },
          { imaddr: "assets/img/special/k100th/15", iminfo: "" },
          { imaddr: "assets/img/special/k100th/16", iminfo: "" },
          { imaddr: "assets/img/special/k100th/17", iminfo: "" },
          { imaddr: "assets/img/special/k100th/18", iminfo: "" },
          { imaddr: "assets/img/special/k100th/18", iminfo: "" }]
      },
      {
        evntid: "gr",
        evntname: "Graduation Day",
        evntquote: "Our Little Graduates",
        evntimage: [
          { imaddr: "assets/img/graduate/2024/1", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/2", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/3", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/4", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/5", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/6", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/7", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/8", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/9", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/10", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/11", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/12", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/13", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/14", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/15", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/16", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/17", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/18", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/19", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/20", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/21", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/22", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/24", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/25", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/26", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/27", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/28", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/29", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/30", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/31", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/32", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/34", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/35", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/36", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/37", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/38", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/39", iminfo: "" },
          { imaddr: "assets/img/graduate/2024/40", iminfo: "" },
        ]
      },
      {
        evntid: "md",
        evntname: "Maathru Pooja",
        evntquote: "The most important quality for a person is respecting their parents, ancestors and others",
        evntimage: [
          
          { imaddr: "assets/img/md/2024/2", iminfo: "" },
          { imaddr: "assets/img/md/2024/3", iminfo: "" },
          { imaddr: "assets/img/md/2024/4", iminfo: "" },
          { imaddr: "assets/img/md/2024/5", iminfo: "" },
          { imaddr: "assets/img/md/2024/6", iminfo: "" },
          { imaddr: "assets/img/md/2024/7", iminfo: "" },
          { imaddr: "assets/img/md/2024/8", iminfo: "" },
          { imaddr: "assets/img/md/2024/9", iminfo: "" },
          { imaddr: "assets/img/md/2024/10", iminfo: "" },
          { imaddr: "assets/img/md/2024/11", iminfo: "" },
          { imaddr: "assets/img/md/2024/12", iminfo: "" },
          { imaddr: "assets/img/md/2024/13", iminfo: "" },
          { imaddr: "assets/img/md/2024/14", iminfo: "" },
          { imaddr: "assets/img/md/2024/15", iminfo: "" },
          { imaddr: "assets/img/md/2024/16", iminfo: "" },
          { imaddr: "assets/img/md/2024/17", iminfo: "" },
          { imaddr: "assets/img/md/2024/18", iminfo: "" },
          { imaddr: "assets/img/md/2024/19", iminfo: "" },
          { imaddr: "assets/img/md/2024/20", iminfo: "" },
          { imaddr: "assets/img/md/2024/21", iminfo: "" },
          { imaddr: "assets/img/md/2024/22", iminfo: "" },
          { imaddr: "assets/img/md/2024/24", iminfo: "" },
          { imaddr: "assets/img/md/2024/25", iminfo: "" },
          { imaddr: "assets/img/md/2024/26", iminfo: "" },
          { imaddr: "assets/img/md/2024/27", iminfo: "" },
          { imaddr: "assets/img/md/2024/28", iminfo: "" },
          { imaddr: "assets/img/md/2024/29", iminfo: "" },
          { imaddr: "assets/img/md/2024/30", iminfo: "" },
          { imaddr: "assets/img/md/2024/31", iminfo: "" },
          { imaddr: "assets/img/md/2024/32", iminfo: "" },
          { imaddr: "assets/img/md/2024/34", iminfo: "" },
          { imaddr: "assets/img/md/2024/35", iminfo: "" },
          { imaddr: "assets/img/md/2024/36", iminfo: "" },
          { imaddr: "assets/img/md/2024/37", iminfo: "" },
        
        ]
      },
      // {
      //   evntid: "an",
      //   evntname: "Annual Day",
      //   evntquote: "Our School age is 34",
      //   evntimage: [
      //     { imaddr: "assets/img/annual/2024/1", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/2", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/3", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/4", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/5", iminfo: "<h5>M.N. Srithar B.Pharm.,M.R.S.H</h5><br><h6>Joint Director of Drugs Control cum,<br>Controlling Authority,<br>Government of Tamilnadu</h6>" },
      //     { imaddr: "assets/img/annual/2024/6", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/7", iminfo: "<h5>A.L.Durga M.com.,M.Ed.,M.Phil.,</h5><br><h6>Head Master, Sourashtra Girls Hr.Sec Schol<br>Anupanadi<br>Madurai</h6>" },
      //     { imaddr: "assets/img/annual/2024/8", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/9", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/10", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/11", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/12", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/14", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/15", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/16", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/17", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/18", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/19", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/20", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/21", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/22", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/24", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/25", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/26", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/27", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/28", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/29", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/30", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/31", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/32", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/34", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/35", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/36", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/37", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/38", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/39", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/40", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/41", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/42", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/44", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/45", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/46", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/47", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/48", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/49", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/50", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/51", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/52", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/54", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/55", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/56", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/57", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/58", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/59", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/60", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/61", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/62", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/63", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/64", iminfo: "" },
      //     { imaddr: "assets/img/annual/2024/65", iminfo: "" },

      //   ],
      // },
      
      
     

      {
        evntid: "an",
        evntname: "Smart Class Room",
        evntquote: "We added New Smart Class Room",
        evntimage: [
          { imaddr: "assets/img/special/nsmartclass/1", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/2", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/3", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/4", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/5", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/6", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/7", iminfo: "" },
          { imaddr: "assets/img/special/nsmartclass/8", iminfo: "" },]
      },
      {
        evntid: "spe",
        evntname: "Special Event",
        evntquote: "Adding 5 more Classes with the help of our Contributors",
        evntimage: [
          { imaddr: "assets/img/special/nopen/2.JPG", iminfo: "" },
          { imaddr: "assets/img/special/nopen/1.JPG", iminfo: "" },
          { imaddr: "assets/img/special/nopen/3.JPG", iminfo: "" },
          { imaddr: "assets/img/special/nopen/4.JPG", iminfo: "" },
        ],
      },

      

      // {
      //   evntid: "cov",
      //   evntname: "Vaccination Camp",
      //   evntquote: "Conducted Vaccination Camp",
      //   evntimage: [
      //     "assets/img/cov/1.JPG",
      //     "assets/img/cov/2.JPG",
      //     "assets/img/cov/3.JPG",
      //     "assets/img/cov/4.JPG",
      //     "assets/img/cov/5.JPG",
      //     "assets/img/cov/6.JPG",
      //     "assets/img/cov/7.JPG",
      //     "assets/img/cov/8.JPG",
      //     "assets/img/cov/9.JPG",
      //     "assets/img/cov/10.JPG",
      //     "assets/img/cov/11.JPG",
      //     "assets/img/cov/12.JPG",
      //     "assets/img/cov/14.JPG",
      //     "assets/img/cov/15.JPG",
      //     "assets/img/cov/16.JPG",
      //     "assets/img/cov/17.JPG",
      //     "assets/img/cov/18.JPG",
      //     "assets/img/cov/19.JPG",
      //     "assets/img/cov/20.JPG",
      //     "assets/img/cov/21.JPG",
      //   ],
      // },
      // {
      //   evntid: "spe",
      //   evntname: "Special Event",
      //   evntquote: "Worship for the Welfare of the World",
      //   evntimage: [
      //     "assets/img/special/wor/1.jpg",
      //     "assets/img/special/wor/2.jpg",
      //     "assets/img/special/wor/3.jpg",
      //     "assets/img/special/wor/4.jpg",
      //     "assets/img/special/wor/5.jpg",
      //     "assets/img/special/wor/6.jpg",
      //   ],
      // },




    ]
    // this.so2.push({})

    // }
    this.ylo = [
      {
        evntid: "so2",
        evntname: "Science Exibhition",
        evntquote: "Science Occupy Everywhere",
        evntimage: [
          { imaddr: "assets/img/sci/1.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/2.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/3.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/4.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/5.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/6.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/7.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/8.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/9.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/10.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/11.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/12.JPG", iminfo: "" },
          { imaddr: "assets/img/sci/13.JPG", iminfo: "" },
        ],
      },





      {
        evntid: "an",
        evntname: "Annual Day",
        evntquote: "Our School age is 32",
        evntimage: [
          { imaddr: "assets/img/annual/2022/1.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/2.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/3.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/4.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/5.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/6.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/7.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/8.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/9.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/10.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/11.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/12.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/14.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/15.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/16.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/17.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/18.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/19.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/20.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/21.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/22.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/24.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/25.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/26.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/27.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/28.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/29.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/30.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/31.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/32.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/34.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/35.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/36.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/37.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/38.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/39.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/40.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/41.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/42.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/44.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/45.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/46.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/47.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/48.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/49.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/50.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/51.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/52.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/54.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/55.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/56.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/57.JPG", iminfo: "" },
          { imaddr: "assets/img/annual/2022/58.JPG", iminfo: "" },

        ],
      },


      {
        evntid: "sp",
        evntname: "Sports Day",
        evntquote: "We believe that both physical and mental health is important",
        evntimage: [
          { imaddr: "assets/img/sports/sports2020/1.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/2.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/3.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/4.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/5.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/6.JPG", iminfo: "" },
          { imaddr: "assets/img/sports/sports2020/7.jpg", iminfo: "" },
        ]

      }
    ]



  }

}
