import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private rout: Router, private http: HttpClient) { }

  efeeds: any;
  cfeeds: any;
  idfeeds: any;
  dt: string[];
  i: any;
  dpd;idts;
  gsm=false;
  ism=false;
  sw=0;
  ngOnInit() {
    this.sw=window.innerWidth;

    this.efeeds = [];
    this.cfeeds = [];
    this.idfeeds = [];
    this.dt = [];
    this.http.get("/assets/img/feedadd.txt", { responseType: 'text' as 'json' }).subscribe((res: any) => {
      this.dt = res.split("^^");
      for (this.i = 0; this.i < this.dt.length; this.i++) {

        (this.dt[this.i]).trimLeft(); (this.dt[this.i]).trimRight();
        if (this.i < 5 && JSON.parse(this.dt[this.i]).type == "Educationist")
          this.efeeds.push(JSON.parse(this.dt[this.i]));
        else
          this.cfeeds.push(JSON.parse(this.dt[this.i]));
        this.idfeeds[this.i] = "#" + JSON.parse(this.dt[this.i]).fdname
      }

    });


    this.dpd = [
      {
        evntid: "k100",
        evntname: "Founder's Day",
        evntquote: "Commencement of Our Founder's centenary",
        evntimage: "assets/img/special/k100th/7",
      },
      {
        evntid: "rep",
        evntname: "Republic Day",
        evntquote: "Celebrated 76th Republic Day",
        evntimage: "assets/img/rep/76/3",
      },
      {
        evntid: "sci",
        evntname: "Science Exhibition & Project Day",
        evntquote: "Science Occupy Everywhere",
        evntimage: "assets/img/sci/2024/25",
      },
      {
        evntid: "diwali",
        evntname: "Deepavali Celebration",
        evntquote: "Celebrated Deepavali with Souco Group",
        evntimage: "assets/img/diwali/24/1",
      },
      {
        evntid: "ind",
        evntname: "Independence Day",
        evntquote: "Celebrated 78th Independence Day",
        evntimage: "assets/img/ind/2024/10",
      },
      {
        evntid: "sprt",
        evntname: "Sports Day",
        evntquote: "Energetic Young Talents",
        evntimage: "assets/img/sports/sports2024/5",
      },
      
      {
        evntid: "grad",
        evntname: "Graduation Day",
        evntquote: "Kindergarten Graduation",
        evntimage: "assets/img/graduate/2024/39",
      },
      {
        evntid: "md",
        evntname: "Maathru Pooja",
        evntquote: "Worshiping Mother",
        evntimage: "assets/img/md/2024/5",
      },
      {
        evntid: "an",
        evntname: "Annual Day",
        evntquote: "Our School age is 34",
        evntimage: "assets/img/annual/2024/37",
      },
   
     
    
      
     
   
      {
        evntid: "ind",
        evntname: "Independence Day",
        evntquote: "Celebrated 76th Independence Day",
        evntimage: "assets/img/ind/2022/gwall.JPG",
      },
      {
        evntid: "Spe",
        evntname: "Special Event",
        evntquote: "We added a New Smart Classroom",
        evntimage: "assets/img/special/nsmartclass/6",
      },
    
      {
        evntid: "Spe",
        evntname: "Special Event",
        evntquote: "We added 5 more classroom",
        evntimage: "assets/img/special/nopen/3.JPG",
      },
     
      {
        evntid: "diwa",
        evntname: "Deepavali Celebration",
        evntquote: "Celebrated Deepavali Celebration with SouCo",
        evntimage: "assets/img/diwali/1.JPG",
      },
     
      // {
      //   evntid: "cov",
      //   evntname: "Vaccination Camp",
      //   evntquote: "Conducted Vaccination Camp",
      //   evntimage: "assets/img/cov/1.JPG",
      // },
     
    
      
      
    ]

this.idts=[
  // {
  //   infname:"Temple",
  //   infsen:"Enlightening area",
  //   infimage:"assets/img/infra/temple.jpg"
  // },
  // {
  //   infname:"Primary School",
  //   infsen:"In Primary School, we are providing an english medium education from KG to 5",
  //   infimage:"assets/img/infra/pschool.jpg"
  // },
  // {
  //   infname:"Vidhyalaya School",
  //   infsen:"In Vidhyalaya School, we are providing a tamil medium education from 1 to 8",
  //   infimage:"assets/img/infra/vidhyalaya.jpg"
  // },
  {
    infname:"OFFICE",
    infsen:"The Important place for all other places",
    infimage:"assets/img/infra/office.jpg"
  },
  {
    infname:"Play Ground",
    infsen:"A Place for our students to enhance their sport skills",
    infimage:"assets/img/infra/infras/7.jpg"
  },
  {
    infname:"Noon Meal Kitchen",
    infsen:"Tamilnadu Government is providing Midday meal for student..",
    infimage:"assets/img/infra/infras/30.jpg"
  },
  {
    infname:"Auditorium",
    infsen:"The Indoor auditorium can accommodate 300 seats",
    infimage:"assets/img/infra/auditorium.jpg"
  },
  {
    infname:"Smart Classroom",
    infsen:"We enhance the learning with latest technologies",
    infimage:"assets/img/infra/infras/32.jpg"
  },
]


  }
  go(gos: string) {
    this.rout.navigate(["/gallery/#" + gos]);
  }
  egsm(){
    this.gsm=this.gsm?false:true;
  }

  eism(){
    this.ism=this.ism?false:true;
  }
}
